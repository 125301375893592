import "../css/app.css";
import  './echo.js'

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from 'ziggy-js';
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";


createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    progress: {
        color: "#29d",
    },
    setup({ el, App, props, plugin }) {
        return (
            createApp({ render: () => h(App, props) })
                .use(ElementPlus)
                .use(plugin)
                .use(ZiggyVue)
                .mixin({
                    methods: {
                        copyToClipboard: function (containerid) {
                            var range = document.createRange();
                            let containerNode =
                                document.getElementById(containerid); //// this part
                            range.selectNode(containerNode); //// this part
                            window.getSelection().removeAllRanges();
                            window.getSelection().addRange(range);
                            document.execCommand("copy");
                            window.getSelection().removeAllRanges();
                            alert("Дані скопійовані");
                        },
                    },
                })
                .mount(el)
        );
    },
});
